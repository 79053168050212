
























import { DataTableColDef, DataTableDef, DataTableRowDef } from '@/app_code/DataTable'
import { DropdownField, DropdownListItem, FormField } from '@/app_code/Forms'
import DataTable from '@/components/UI/Elements/DataTable/DataTable.vue'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { CountryModel, CreateZoneModel, FranchiseModel, ZoneModel } from 'truemarket-modules/src/models/api/admin'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    DataTable
  }
})
export default class Edit extends Vue {
  private isAddingZone = false

  private dtDef = new DataTableDef(new DataTableRowDef(row => row.ZoneId, [
    new DataTableColDef('TM Code', row => row.TMCode),
    new DataTableColDef('TM Name', row => row.TMName)
  ]))

  private zones: ZoneModel[] = []

  private country: CountryModel | null = null

  private countryId = ''

  private fields: FormField[] = []

  private franchises: FranchiseModel[] = []

  mounted () {
    this.countryId = this.$route.params.id

    this.updateZones()
  }

  updateZones () {
    Services.API.Admin.Countries.GetCountry(this.countryId).then((country) => {
      this.country = country

      Services.API.Admin.Countries.GetZones(this.countryId).then((zones) => {
        this.zones = zones
      })
    })
  }

  addZone () {
    const model = (this.$refs.addZoneForm as Form).GetValues().BuildModel<CreateZoneModel>()

    Services.API.Admin.Countries.CreateZone(this.countryId, model).then(() => {
      this.updateZones()

      this.isAddingZone = false
    })
  }

  editZone (zoneId: string) {
    this.$router.push(`/dashboard/admin/zones/${zoneId}`)
  }
}
